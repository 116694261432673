<script lang="ts" setup>
import { useTemplateRef, computed } from 'vue';
import { useVModel, useFocus, unrefElement } from '@vueuse/core';
import { IconX } from '@tabler/icons-vue';
import type { SizeS, SizeM, SizeL } from '../../shared/types';
import { ObPrimitiveInput } from '../primitive-input';
import { ObDecoratedInput } from '../decorated-input';

interface Props {
  autocomplete?: string; // TODO: list all allowed value? https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
  clearable?: boolean;
  disabled?: boolean;
  filler?: string;
  inputMode?: 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
  invalid?: boolean;
  maxLength?: number;
  minLength?: number;
  modelValue?: string;
  modelModifiers?: Record<string, boolean>;
  placeholder?: string;
  postfix?: string;
  prefix?: string;
  readonly?: boolean;
  size?: SizeS | SizeM | SizeL;
  tabindex?: number;
  type?: 'text' | 'password' | 'email' | 'tel' | 'url' | 'search' | 'number';
}

const props = withDefaults(defineProps<Props>(), {
  autocomplete: undefined,
  clearable: false,
  disabled: false,
  filler: '',
  inputMode: 'text',
  invalid: false,
  maxLength: undefined,
  minLength: undefined,
  modelValue: '',
  modelModifiers: () => ({}),
  placeholder: '',
  postfix: '',
  prefix: '',
  readonly: false,
  size: 'm',
  tabindex: undefined,
  type: 'text',
});

const emit = defineEmits<{
  clear: [];
  'update:modelValue': [value: number | null];
}>();

const modelValue = useVModel(props, 'modelValue', emit, { passive: true });

const inputRef = useTemplateRef('input');

const { focused } = useFocus(inputRef);

function clear(): void {
  modelValue.value = '';
  emit('clear');
}

function focus() {
  inputRef.value?.focus();
}

function onWrapperMouseDown(event: MouseEvent) {
  if (event.target === unrefElement(inputRef)) {
    return;
  }

  event.preventDefault();
  focus();
}

defineExpose({
  focus() {
    focus();
  },
  input: computed(() => inputRef.value),
});
</script>

<template>
  <!-- eslint-disable vue/no-unused-refs TODO: https://github.com/vuejs/eslint-plugin-vue/pull/2541 -->
  <ObPrimitiveInput
    tabindex="-1"
    :size="props.size"
    :disabled="props.disabled"
    :invalid="props.invalid"
    :focused="focused"
    @focus="focus()"
    @mousedown="onWrapperMouseDown"
  >
    <div :class="$style.root">
      <ObDecoratedInput
        :prefix="props.prefix"
        :postfix="props.postfix"
        :placeholder="props.placeholder"
        :filler="props.filler"
        :value="modelValue"
      >
        <input
          ref="input"
          v-model="modelValue"
          :autocomplete="props.autocomplete"
          :aria-invalid="props.invalid"
          :disabled="props.disabled"
          :inputmode="props.inputMode"
          :maxlength="props.maxLength"
          :minlength="props.minLength"
          :readonly="props.readonly"
          :tabindex="props.tabindex"
          :type="props.type"
        />
      </ObDecoratedInput>
    </div>
    <template #utils>
      <button
        v-if="props.clearable && modelValue"
        type="button"
        :class="$style.cleaner"
        tabindex="-1"
        aria-label="Clear"
        @click="clear()"
      >
        <IconX aria-hidden="true" />
      </button>
    </template>
    <template #icon>
      <slot name="icon" />
    </template>
    <template #addon>
      <slot name="addon" />
    </template>
  </ObPrimitiveInput>
</template>

<style lang="scss" module>
@use '../../styles/shared';

.root {
  display: flex;
  height: 100%;
  padding: 0 12px;
  box-sizing: border-box;
}

.cleaner {
  @include shared.reset-button();
  display: flex;
  color: inherit;
  font-size: 24px;
  width: 1em;
  height: 1em;
  cursor: pointer;
}
</style>
