<script lang="ts" setup>
import {
  ref,
  computed,
  watchEffect,
  watch,
  nextTick,
  useTemplateRef,
  shallowRef,
  type VNodeProps,
  type VNode,
  type CSSProperties,
} from 'vue';
import { useFloating, flip, autoUpdate, shift, offset } from '@floating-ui/vue';
import { onClickOutside, unrefElement } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { ObScrollableContainer } from '../scrollable-container';
import { ObActionListContextProvider } from '../action-list';
import { useZIndex, useFocusZone, useFocusScope } from '../../composables';
import { getClosestFocusable } from '../../utils';

interface Props {
  open?: boolean;
}

defineOptions({
  inheritAttrs: false,
});

defineProps<Props>();

defineEmits<{
  'update:open': [open: boolean];
}>();

defineSlots<{
  default?: () => VNode;
  host?: (props: { open: boolean; hostProps: VNodeProps }) => VNode;
}>();

// TODO: is it possible to use `useTemplateRef` with Function Refs?
const hostRef = shallowRef<HTMLElement | null>(null);
const containerRef = useTemplateRef('container');

const open = defineModel<boolean>('open', { default: false });

const portalActive = ref(false);

watchEffect(() => {
  if (open.value) {
    portalActive.value = true;
  }
});

const { zIndex } = useZIndex({ active: portalActive });

const { floatingStyles } = useFloating(hostRef, containerRef, {
  placement: 'bottom-start',
  middleware: [offset(8), flip(), shift()],
  whileElementsMounted: autoUpdate,
  open: portalActive,
  transform: false,
});

const { focusFirst, focusLast } = useFocusZone({
  container: containerRef,
  disabled: computed(() => !portalActive.value),
});

const { activate: activateTrap, deactivate: deactivateTrap } = useFocusTrap(containerRef, {
  escapeDeactivates: false,
  allowOutsideClick: true,
  clickOutsideDeactivates: true,
  returnFocusOnDeactivate: false,
  fallbackFocus: () => {
    return containerRef.value as HTMLElement;
  },
});

useFocusScope(containerRef);

onClickOutside(
  containerRef,
  () => {
    open.value = false;
  },
  { ignore: [hostRef] },
);

watch(
  open,
  (value) => {
    if (value) {
      nextTick(() => {
        activateTrap();
      });
      return;
    }

    deactivateTrap();
  },
  { immediate: true },
);

function onEsc(event: KeyboardEvent) {
  event.preventDefault();
  event.stopPropagation();
  open.value = false;
  nextTick(() => {
    // TODO: maybe manage with useFocusTrap config?
    hostRef.value?.focus();
  });
}

function onTab(event: KeyboardEvent) {
  event.preventDefault();
  event.stopPropagation();

  open.value = false;

  nextTick(() => {
    if (!hostRef.value) {
      return;
    }

    const target =
      getClosestFocusable({
        initial: hostRef.value,
        root: document.documentElement,
        previous: event.shiftKey,
      }) ?? hostRef.value;

    target?.focus();
  });
}

function onHostClick(event: MouseEvent) {
  event.preventDefault();
  open.value = !open.value;
}

function onHostKeydown(event: KeyboardEvent) {
  if (event.key === 'ArrowDown') {
    event.preventDefault();
    open.value = true;
    nextTick(() => {
      focusFirst();
    });
    return;
  }

  if (event.key === 'ArrowUp') {
    event.preventDefault();
    open.value = true;
    nextTick(() => {
      focusLast();
    });
    return;
  }
}

function onItemSelect() {
  open.value = false;
  nextTick(() => {
    // TODO: maybe manage with useFocusTrap config?
    hostRef.value?.focus();
  });
}

const containerStyle = computed<CSSProperties>(() => ({
  ...floatingStyles.value,
  zIndex: zIndex.value,
}));

const hostProps = computed(() => ({
  // TODO: fix type
  ref: (el: any) => {
    hostRef.value = unrefElement(el);
  },
  onClick: onHostClick,
  onKeydown: onHostKeydown,
  'aria-haspopup': true,
  'aria-expanded': open.value,
}));
</script>

<template>
  <!-- eslint-disable vue/no-unused-refs TODO: https://github.com/vuejs/eslint-plugin-vue/pull/2541 -->
  <slot name="host" v-bind="{ open, hostProps }" />
  <Teleport v-if="portalActive" to="body">
    <Transition
      appear
      mode="in-out"
      :enter-from-class="$style.enterFrom"
      :enter-active-class="$style.enterActive"
      :leave-active-class="$style.leaveActive"
      :leave-to-class="$style.leaveTo"
      @after-leave="portalActive = false"
    >
      <div
        v-if="open"
        v-bind="$attrs"
        ref="container"
        :style="containerStyle"
        :class="$style.container"
        tabindex="-1"
        role="none"
        @keydown.esc="onEsc"
        @keydown.tab="onTab"
      >
        <ObScrollableContainer light>
          <ObActionListContextProvider
            :on-after-select="onItemSelect"
            list-role="menu"
            item-role="menuitem"
          >
            <slot />
          </ObActionListContextProvider>
        </ObScrollableContainer>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.container {
  box-sizing: border-box;
  min-width: 200px;
  max-width: 500px;
  width: auto;
  max-height: 480px;
  border-radius: 12px;
  background: #fff;
  color: colors.$primary;
  border: 1px solid colors.$surface-6;
  box-shadow: 0px 0px 18px 0px rgba(2, 17, 72, 0.2);
  font-family: typography.$font-family-primary;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.enterActive,
.leaveActive {
  transition-property: opacity, transform;
  transition-duration: 0.2s;
}

.enterActive {
  transition-timing-function: ease-out;
}

.leaveActive {
  transition-timing-function: ease-in;
}

.enterFrom,
.leaveTo {
  opacity: 0;
  transform: scale(0.9);
}
</style>
