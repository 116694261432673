<script lang="ts" setup>
import { onMounted, onUnmounted, useId } from 'vue';
import { useButton } from '../../composables';
import { injectStrict } from '../../utils';
import { BATCH_ACTIONS_MENU_CONTEXT } from './shared';

// TODO: a11y, how to manage focus?

interface Props {
  disabled?: boolean;
  tabIndex?: string;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  tabIndex: undefined,
});

const { register, unregister, verticalItemsLayout } = injectStrict(
  BATCH_ACTIONS_MENU_CONTEXT,
  undefined,
  '`<ObBatchActionsMenuItem /> must be child of <ObBatchActionsMenu /> component.`',
);

const { buttonProps } = useButton(props);

const id = useId();

onMounted(() => {
  register(id);
});

onUnmounted(() => {
  unregister(id);
});
</script>

<template>
  <button
    :class="[
      $style.root,
      { [$style.disabled]: props.disabled, [$style.vertical]: verticalItemsLayout },
    ]"
    v-bind="buttonProps"
    type="button"
  >
    <span v-if="$slots.icon" :class="$style.icon">
      <slot name="icon" />
    </span>
    <span v-if="$slots.default" :class="$style.text">
      <slot />
    </span>
  </button>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';
@use '../../styles/iconography';

.root {
  @include shared.reset-button();
  height: 100%;
  padding: 0 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  color: colors.$primary;
  background-color: transparent;
  border-color: transparent;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  flex: 1 0 auto;

  &:focus-visible .icon {
    box-shadow: inset 0 0 0 1px colors.$hyperlink;
  }

  &:hover:enabled .icon {
    color: #7a68e3;
  }
}

.icon {
  flex: none;
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  padding: 4px;
  box-sizing: border-box;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.text {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.icon + .text {
  margin-left: 4px;
}

.vertical {
  flex-direction: column;
  padding: 0;
}

.vertical .icon + .text {
  margin: 4px 0 0;
}

.disabled {
  color: #9aa0b6;
  cursor: not-allowed;
}

.disabled .icon {
  color: #c2c6d3;
}
</style>
