<script lang="ts" setup generic="O = any, V = any">
import {
  ref,
  computed,
  watchEffect,
  nextTick,
  shallowRef,
  useId,
  watch,
  type VNode,
  type CSSProperties,
} from 'vue';
import { onClickOutside, unrefElement, useFocus } from '@vueuse/core';
import { IconChevronDown } from '@tabler/icons-vue';
import {
  useFloating,
  flip,
  autoUpdate,
  shift,
  offset,
  size as sizeMiddleware,
} from '@floating-ui/vue';
import { useZIndex, useFocusZone } from '../../composables';
import {
  useOptionsList,
  type OptionsListItem,
  type OptionsListSelectedOption,
} from '../../composables/use-options-list-new';
import { Keys } from '../../shared/enums';
import { ObActionListContextProvider, ObActionList, ObActionListItem } from '../action-list';
import { ObScrollableContainer } from '../scrollable-container';
import { ObPrimitiveInput } from '../primitive-input';
import { ObSpinner } from '../spinner';
import type { SizeS, SizeM, SizeL, OptionsListProps } from '../../shared/types';

type Props = OptionsListProps<O, V> & {
  id?: string;
  invalid?: boolean;
  disabled?: boolean;
  modelValue?: V | null;
  placeholder?: string;
  size?: SizeS | SizeM | SizeL;
  externalSearch?: boolean;
  optionsLoading?: boolean;
};

defineOptions({
  inheritAttrs: false,
});

const {
  id,
  disabled = false,
  invalid = false,
  size = 'm',
  options = [],
  optionDisabled,
  optionLabel,
  optionValue,
  trackValueBy,
  optionsLoading = false,
  externalSearch = false,
} = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value: V | null];
  search: [search: string];
}>();

defineSlots<{
  default?: (props: {
    optionsList: OptionsListItem<O, V>[];
    selectOption: (option: OptionsListItem<O, V>) => void;
    selectedOption: OptionsListSelectedOption<O, V> | null;
  }) => VNode;
  prefix?: () => VNode;
}>();

// TODO: wtf with useTemplateRef?
const hostRef = shallowRef<HTMLElement | null>();
const inputRef = shallowRef<HTMLElement | null>();
const containerRef = shallowRef<HTMLElement | null>();

const open = ref(false);
const portalActive = ref(false);

watchEffect(() => {
  if (open.value) {
    portalActive.value = true;
  }
});

onClickOutside(
  containerRef,
  () => {
    open.value = false;
  },
  { ignore: [hostRef] },
);

const { zIndex } = useZIndex({ active: portalActive });

const { floatingStyles } = useFloating(hostRef, containerRef, {
  placement: 'bottom-start',
  middleware: [
    sizeMiddleware({
      apply({ elements }) {
        const { width } = elements.reference.getBoundingClientRect();
        Object.assign(elements.floating.style, {
          width: `${width}px`,
        });
      },
    }),
    offset(8),
    flip(),
    shift(),
  ],
  whileElementsMounted: autoUpdate,
  open: portalActive,
  transform: false,
});

const containerStyle = computed<CSSProperties>(() => ({
  ...floatingStyles.value,
  zIndex: zIndex.value,
}));

const listId = useId();
const { focused } = useFocus(inputRef);

let activeDescendant: HTMLElement | undefined;

const { focusFirst, focusLast, focus } = useFocusZone({
  container: containerRef,
  activeDescendantControl: inputRef,
  onActiveDescendantChanged: (current, previous, directlyActivated) => {
    activeDescendant = current;

    if (directlyActivated) {
      activeDescendant?.scrollIntoView({ block: 'nearest', inline: 'start' });
    }
  },
  disabled: computed(() => !portalActive.value),
});

const modelValue = defineModel<V | null>({ default: null });

const inputValue = ref('');
const search = ref('');

watch(search, (value) => {
  emit('search', value);
});

const { filteredOptionsList: optionsList, selectedOption } = useOptionsList<O, V>({
  options: computed(() => options), // reactivity
  optionDisabled,
  optionLabel,
  optionValue,
  trackValueBy,
  selectionMode: 'single',
  selected: modelValue,
  search: computed(() => {
    if (externalSearch) {
      return '';
    }

    return search.value;
  }),
});

watchEffect(() => {
  if (selectedOption.value) {
    search.value = '';
    inputValue.value = selectedOption.value.label;
  }
});

function selectOption(option: OptionsListItem<O, V>) {
  modelValue.value = option.value;
}

function onItemSelect() {
  open.value = false;
  search.value = '';
}

function resetInputValue() {
  inputValue.value = selectedOption.value ? selectedOption.value.label : '';
}

function focusInput() {
  inputRef.value?.focus({ preventScroll: true });
}

function onClick(event: MouseEvent) {
  if (disabled) {
    return;
  }

  if (event.target === unrefElement(inputRef)) {
    open.value = true;
  } else {
    event.preventDefault();
    open.value = !open.value;
    focusInput();
  }

  if (open.value) {
    nextTick(() => {
      const selected =
        unrefElement(containerRef)?.querySelector<HTMLElement>('[aria-selected="true"]');
      if (selected) {
        focus(selected);
      }
    });
  }
}

const EDITING_KEYS: string[] = [Keys.Space, Keys.Backspace, Keys.Delete];

function onInputKeydown(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    event.preventDefault();
    event.stopPropagation();
    open.value = false;
    search.value = '';
    resetInputValue();
    return;
  }

  if (open.value) {
    return;
  }

  if (event.key === Keys.ArrowDown || event.key === Keys.ArrowUp) {
    event.preventDefault();
    event.stopPropagation();
    open.value = true;
    nextTick(() => {
      if (event.key === Keys.ArrowDown) {
        focusFirst(true);
        return;
      }

      focusLast(true);
    });

    return;
  }

  if (event.key.length === 1 || EDITING_KEYS.includes(event.key)) {
    open.value = true;
  }
}

function onInputKeypress(event: KeyboardEvent) {
  if (open.value) {
    if (event.key === Keys.Enter && activeDescendant) {
      event.preventDefault();
      event.stopImmediatePropagation();

      // Forward Enter key press to active descendant so that item gets activated
      const activeDescendantEvent = new KeyboardEvent(event.type, event);
      activeDescendant.dispatchEvent(activeDescendantEvent);
    }
    return;
  }
}

function onBlur(event: FocusEvent) {
  let relatedTarget = event.relatedTarget as HTMLElement;

  const hostElement = unrefElement(hostRef);

  if (hostElement === relatedTarget || hostElement?.contains(relatedTarget)) {
    return;
  }

  if (unrefElement(containerRef)?.contains(relatedTarget)) {
    return;
  }

  open.value = false;
  search.value = '';
  resetInputValue();
}

function onInput(event: Event) {
  const { value } = event.target as HTMLInputElement;
  search.value = value;
  inputValue.value = value;

  if (!value) {
    modelValue.value = null;
  }

  nextTick(() => {
    focusFirst();
  });
}
</script>

<template>
  <!-- eslint-disable vue/no-unused-refs TODO: https://github.com/vuejs/eslint-plugin-vue/pull/2541 -->
  <ObPrimitiveInput
    ref="hostRef"
    :size
    :invalid
    :disabled
    :focused
    tabindex="-1"
    @focus="focusInput()"
    @click="onClick"
    @focusout="onBlur"
  >
    <input
      :id
      ref="inputRef"
      v-model="inputValue"
      type="text"
      :class="$style.input"
      :placeholder="placeholder"
      :disabled
      aria-autocomplete="list"
      :aria-controls="listId"
      :aria-expanded="open"
      aria-haspopup="listbox"
      :aria-owns="listId"
      autocomplete="off"
      role="combobox"
      @keydown="onInputKeydown"
      @keypress="onInputKeypress"
      @input="onInput"
    />
    <template #icon>
      <span :class="[$style.arrow, { [$style.arrowRotated]: open }]">
        <IconChevronDown />
      </span>
    </template>
  </ObPrimitiveInput>
  <Teleport v-if="portalActive" to="body">
    <Transition
      appear
      mode="in-out"
      :enter-from-class="$style.enterFrom"
      :enter-active-class="$style.enterActive"
      :leave-active-class="$style.leaveActive"
      :leave-to-class="$style.leaveTo"
      @after-leave="portalActive = false"
    >
      <div
        v-if="open && !disabled"
        v-bind="$attrs"
        ref="containerRef"
        :style="containerStyle"
        :class="$style.container"
        tabindex="-1"
        role="dialog"
      >
        <ObScrollableContainer light>
          <div v-if="optionsLoading" :class="$style.noData">
            <ObSpinner size="48px" />
            Loading
          </div>
          <div v-else-if="!optionsList.length" :class="$style.noData">
            {{ search ? `No items found for '${search}'` : 'No items found' }}
          </div>
          <ObActionListContextProvider
            v-else
            list-role="listbox"
            item-role="option"
            :on-after-select="onItemSelect"
          >
            <ObActionList :id="listId" selection-mode="single" compact>
              <slot
                v-bind="{
                  optionsList,
                  selectedOption,
                  selectOption,
                }"
              >
                <ObActionListItem
                  v-for="option in optionsList"
                  :key="option.label"
                  :selected="option.selected"
                  :disabled="option.disabled"
                  @select="selectOption(option)"
                >
                  {{ option.label }}
                </ObActionListItem>
              </slot>
            </ObActionList>
          </ObActionListContextProvider>
        </ObScrollableContainer>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.input {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  text-align: inherit;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-transform: inherit;
  border-radius: inherit;
  background: none;
  caret-color: currentColor;
  outline: none;
  appearance: none;
  word-break: keep-all;
  padding: 0 12px;

  &::placeholder {
    color: colors.$surface-40;
    white-space: pre;
  }
}

.container {
  box-sizing: border-box;
  max-height: 480px;
  width: auto;
  border-radius: 12px;
  background: #fff;
  color: colors.$primary;
  border: 1px solid colors.$surface-6;
  box-shadow: 0px 0px 18px 0px rgba(2, 17, 72, 0.2);
  font-family: typography.$font-family-primary;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.enterActive,
.leaveActive {
  transition-property: opacity, transform;
  transition-duration: 0.2s;
}

.enterActive {
  transition-timing-function: ease-out;
}

.leaveActive {
  transition-timing-function: ease-in;
}

.enterFrom,
.leaveTo {
  opacity: 0;
  transform: scale(0.9);
}

.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  gap: 4px;
  padding: 48px 8px;
}

.arrow {
  color: colors.$surface-40;
  display: flex;
  font-size: 24px;
  width: 1em;
  height: 1em;
  margin-left: 12px;
  transition: transform 0.2s ease-in-out;
}

.arrowRotated {
  transform: rotate(180deg);
}
</style>
