<script lang="ts" setup generic="O = any, V = any">
import {
  ref,
  computed,
  watch,
  watchEffect,
  nextTick,
  useId,
  useTemplateRef,
  type VNode,
  type CSSProperties,
} from 'vue';
import { onClickOutside, unrefElement } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { IconChevronDown } from '@tabler/icons-vue';
import {
  useFloating,
  flip,
  autoUpdate,
  shift,
  offset,
  size as sizeMiddleware,
} from '@floating-ui/vue';
import { useZIndex, useFocusZone, useFocusScope } from '../../composables';
import {
  useOptionsList,
  type OptionsListItem,
  type OptionsListSelectedOption,
} from '../../composables/use-options-list-new';
import { ObActionListContextProvider, ObActionList, ObActionListItem } from '../action-list';
import { ObScrollableContainer } from '../scrollable-container';
import type { SizeS, SizeM, SizeL, OptionsListProps } from '../../shared/types';
import { getClosestFocusable, hasSlotContent } from '../../utils';

type Props = OptionsListProps<O, V> & {
  open?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  modelValue?: V | null;
  placeholder?: string;
  size?: SizeS | SizeM | SizeL;
};

defineOptions({
  inheritAttrs: false,
});

const {
  disabled = false,
  invalid = false,
  size = 'm',
  options = [],
  optionDisabled,
  optionLabel,
  optionValue,
  trackValueBy,
} = defineProps<Props>();

defineEmits<{
  'update:modelValue': [value: V | null];
  'update:open': [open: boolean];
}>();

defineSlots<{
  default?: (props: {
    optionsList: OptionsListItem<O, V>[];
    selectOption: (option: OptionsListItem<O, V>) => void;
    selectedOption: OptionsListSelectedOption<O, V> | null;
  }) => VNode;
  prefix?: () => VNode;
}>();

const modelValue = defineModel<V | null>({ default: null });

const { optionsList, selectedOption } = useOptionsList<O, V>({
  options: computed(() => options), // reactivity
  optionDisabled,
  optionLabel,
  optionValue,
  trackValueBy,
  selectionMode: 'single',
  selected: modelValue,
});

// TODO: make this methods part of useOptionsList()

function selectOption(option: OptionsListItem<O, V>) {
  modelValue.value = option.value;
}

const hostRef = useTemplateRef('host');
const containerRef = useTemplateRef('container');

const open = defineModel<boolean>('open', { default: false });

const portalActive = ref(false);

watchEffect(() => {
  if (open.value) {
    portalActive.value = true;
  }
});

const { zIndex } = useZIndex({ active: portalActive });

const { floatingStyles } = useFloating(hostRef, containerRef, {
  placement: 'bottom-start',
  middleware: [
    sizeMiddleware({
      apply({ elements }) {
        const { width } = elements.reference.getBoundingClientRect();
        Object.assign(elements.floating.style, {
          width: `${width}px`,
        });
      },
    }),
    offset(8),
    flip(),
    shift(),
  ],
  whileElementsMounted: autoUpdate,
  open: portalActive,
  transform: false,
});

const { focusFirst, focusLast, focus } = useFocusZone({
  container: containerRef,
  disabled: computed(() => !portalActive.value),
});

const { activate: activateTrap, deactivate: deactivateTrap } = useFocusTrap(containerRef, {
  escapeDeactivates: false,
  allowOutsideClick: true,
  clickOutsideDeactivates: true,
  returnFocusOnDeactivate: false,
  fallbackFocus: () => {
    return containerRef.value as HTMLElement;
  },
});

useFocusScope(containerRef);

onClickOutside(
  containerRef,
  () => {
    open.value = false;
  },
  { ignore: [hostRef] },
);

watch(
  open,
  (value) => {
    if (value) {
      nextTick(() => {
        activateTrap();
      });
      return;
    }

    deactivateTrap();
  },
  { immediate: true },
);

function onEsc(event: KeyboardEvent) {
  event.preventDefault();
  event.stopPropagation();
  open.value = false;
  nextTick(() => {
    // TODO: maybe manage with useFocusTrap config?
    hostRef.value?.focus();
  });
}

function onTab(event: KeyboardEvent) {
  event.preventDefault();
  event.stopPropagation();

  open.value = false;

  nextTick(() => {
    if (!hostRef.value) {
      return;
    }

    const target =
      getClosestFocusable({
        initial: hostRef.value,
        root: document.documentElement,
        previous: event.shiftKey,
      }) ?? hostRef.value;

    target?.focus();
  });
}

function onHostClick(event: MouseEvent) {
  event.preventDefault();
  open.value = !open.value;

  if (open.value) {
    nextTick(() => {
      const selected =
        unrefElement(containerRef)?.querySelector<HTMLElement>('[aria-selected="true"]');
      if (selected) {
        focus(selected);
      }
    });
  }
}

function onHostKeydown(event: KeyboardEvent) {
  if (event.key === 'ArrowDown') {
    event.preventDefault();
    open.value = true;
    nextTick(() => {
      focusFirst();
    });
    return;
  }

  if (event.key === 'ArrowUp') {
    event.preventDefault();
    open.value = true;
    nextTick(() => {
      focusLast();
    });
    return;
  }
}

function onItemSelect() {
  open.value = false;
  nextTick(() => {
    // TODO: maybe manage with useFocusTrap config?
    hostRef.value?.focus();
  });
}

const containerStyle = computed<CSSProperties>(() => ({
  ...floatingStyles.value,
  zIndex: zIndex.value,
}));

const listId = useId();

// TODO: focus on selected option when open
</script>

<template>
  <!-- eslint-disable vue/no-unused-refs TODO: https://github.com/vuejs/eslint-plugin-vue/pull/2541 -->
  <button
    ref="host"
    type="button"
    :class="[
      $style.button,
      {
        [$style.disabled]: disabled,
        [$style.invalid]: invalid,
        [$style.focused]: open,
        [$style.sizeS]: size === 's',
        [$style.sizeM]: size === 'm',
        [$style.sizeL]: size === 'l',
      },
    ]"
    :disabled="disabled"
    aria-haspopup="listbox"
    :aria-expanded="open"
    :aria-controls="listId"
    :aria-owns="listId"
    aria-autocomplete="list"
    @click="onHostClick"
    @keydown="onHostKeydown"
  >
    <span v-if="hasSlotContent($slots.prefix)" :class="$style.prefix">
      <slot name="prefix" />
    </span>
    <span :class="$style.value">
      <template v-if="selectedOption">{{ selectedOption.label }}</template>
      <span v-else-if="placeholder" :class="$style.placeholder">
        {{ placeholder }}
      </span>
    </span>
    <span :class="[$style.arrow, { [$style.arrowRotated]: open }]">
      <IconChevronDown aria-hidden="true" />
    </span>
  </button>
  <Teleport v-if="portalActive" to="body">
    <Transition
      appear
      mode="in-out"
      :enter-from-class="$style.enterFrom"
      :enter-active-class="$style.enterActive"
      :leave-active-class="$style.leaveActive"
      :leave-to-class="$style.leaveTo"
      @after-leave="portalActive = false"
    >
      <div
        v-if="open"
        v-bind="$attrs"
        ref="container"
        :style="containerStyle"
        :class="$style.container"
        tabindex="-1"
        role="dialog"
        @keydown.esc="onEsc"
        @keydown.tab="onTab"
      >
        <ObScrollableContainer light>
          <div v-if="!optionsList.length" :class="$style.noData">No options</div>
          <ObActionListContextProvider
            v-else
            list-role="listbox"
            item-role="option"
            :on-after-select="onItemSelect"
          >
            <ObActionList :id="listId" selection-mode="single" compact>
              <slot
                v-bind="{
                  optionsList,
                  selectedOption,
                  selectOption,
                }"
              >
                <ObActionListItem
                  v-for="option in optionsList"
                  :key="option.label"
                  :selected="option.selected"
                  :disabled="option.disabled"
                  @select="selectOption(option)"
                >
                  {{ option.label }}
                </ObActionListItem>
              </slot>
            </ObActionList>
          </ObActionListContextProvider>
        </ObScrollableContainer>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.container {
  box-sizing: border-box;
  max-height: 480px;
  width: auto;
  border-radius: 12px;
  background: #fff;
  color: colors.$primary;
  border: 1px solid colors.$surface-6;
  box-shadow: 0px 0px 18px 0px rgba(2, 17, 72, 0.2);
  font-family: typography.$font-family-primary;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.enterActive,
.leaveActive {
  transition-property: opacity, transform;
  transition-duration: 0.2s;
}

.enterActive {
  transition-timing-function: ease-out;
}

.leaveActive {
  transition-timing-function: ease-in;
}

.enterFrom,
.leaveTo {
  opacity: 0;
  transform: scale(0.9);
}

.button {
  @include shared.reset-button();
  display: flex;
  text-align: left;
  align-items: center;
  font-size: inherit;
  font-weight: inherit;
  width: 100%;
  height: 100%;
  padding: 0 12px;
  font-family: typography.$font-family-primary;
  color: colors.$primary;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  border-radius: shared.$border-radius-s;
  min-height: 44px;
  display: flex;
  box-sizing: border-box;
  text-align: left;

  &::after {
    @include shared.coverer();
    content: '';
    border-radius: inherit;
    border: 1px solid colors.$surface-16;
    pointer-events: none;
    box-sizing: border-box;
  }
}

.value {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.placeholder {
  color: colors.$surface-40;
}

.arrow {
  color: colors.$surface-40;
  display: flex;
  font-size: 24px;
  width: 1em;
  height: 1em;
  margin-left: 12px;
  transition: transform 0.2s ease-in-out;
}

.arrowRotated {
  transform: rotate(180deg);
}

.button:focus,
.focused {
  &::after {
    border-color: #907ff5; // TODO: use token
  }
}

.invalid {
  &::after {
    border-color: colors.$status-danger;
  }
}

.disabled {
  color: colors.$surface-40;
  background-color: colors.$surface-4;
  cursor: not-allowed;
}

.sizeS {
  min-height: 32px;
}
.sizeM {
  min-height: 44px;
}
.sizeL {
  min-height: 56px;
}

.prefix {
  color: colors.$surface-40;
  margin-right: 4px;
}
</style>
