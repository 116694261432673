<script lang="ts" setup>
import {
  onMounted,
  onUnmounted,
  watchEffect,
  computed,
  inject,
  useSlots,
  h,
  ref,
  useId,
} from 'vue';
import { unrefElement } from '@vueuse/core';
import { injectStrict } from '../../utils';
import { Keys } from '../../shared/enums';
import { OB_ABSTRACT_DISCLOSURE_CONTEXT, OB_ABSTRACT_DISCLOSURE_PANEL_CONTEXT } from './shared';

interface Props {
  disabled?: boolean;
  id?: string;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  id: () => useId(),
});

const context = injectStrict(
  OB_ABSTRACT_DISCLOSURE_CONTEXT,
  undefined,
  '<ObAbstractDisclosureButton /> must be a child of <ObAbstractDisclosure /> component.',
);
const panelContext = inject(OB_ABSTRACT_DISCLOSURE_PANEL_CONTEXT, null);

const withinPanel = computed(() =>
  panelContext === null ? false : panelContext?.value === context.panelId.value,
);

const elementRef = ref<HTMLElement | null>(null);

function toggleDisclosure() {
  context.toggle();

  if (withinPanel.value) {
    unrefElement(context.buttonRef)?.focus();
  }
}

function onClick() {
  if (props.disabled) {
    return;
  }
  toggleDisclosure();
}

function onKeydown(event: KeyboardEvent) {
  if (props.disabled) {
    return;
  }

  switch (event.key) {
    case Keys.Space:
    case Keys.Enter:
      event.preventDefault();
      event.stopPropagation();
      toggleDisclosure();
      break;
    default:
      break;
  }
}

onMounted(() => {
  context.buttonId.value = props.id;
});

onUnmounted(() => {
  context.buttonId.value = null;
});

if (!withinPanel.value) {
  watchEffect(() => {
    context.buttonRef.value = elementRef.value;
  });
}

const slots = useSlots();

defineRender(() => {
  const children =
    slots.default &&
    slots.default({
      expanded: context.expanded.value,
    });

  if (!children?.[0]) {
    return null;
  }

  return children?.length
    ? h(children[0], {
        'aria-controls': context.panelId?.value,
        'aria-expanded': props.disabled ? undefined : context.expanded.value,
        onClick,
        onKeydown,
        ref: elementRef,
      })
    : null;
});
</script>
